import { Checkbox, Grid } from "@mui/material";
import { DocumentListFilterFormInputs } from "@src/components/forms/debitors-creditors/DocumentListFilterForm";
import {
    QuickDocumentFilters,
    selectQuickDocumentFilters,
    setQuickDocumentFilters,
} from "@src/features/document-filters/quick-document-filters-slice";
import { useAppDispatch, useAppSelector } from "@src/hooks/redux";
import { ChangeEvent, FC, ReactNode } from "react";

interface DocumentQuickFilterWrapperProps {
    filter: keyof DocumentListFilterFormInputs;
    quickFilterMode?: boolean;
    children: ReactNode;
    sizes?: { xs: number };
}

export const disabledQuickFilters = ["freeText", "type"];

const DocumentQuickFilterWrapper: FC<DocumentQuickFilterWrapperProps> = ({
    filter,
    children,
    quickFilterMode = false,
    sizes = { xs: 12 },
}) => {
    const dispatch = useAppDispatch();
    const quickFilters = useAppSelector<QuickDocumentFilters>(selectQuickDocumentFilters);

    const onCheckBoxChange = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(setQuickDocumentFilters({ input: filter, value: e.target.checked }));
    };

    if (quickFilterMode && !quickFilters[filter]) {
        return null;
    }

    const renderCheckBox = (): ReactNode => {
        if (quickFilterMode) {
            return null;
        }

        if (disabledQuickFilters.includes(filter)) {
            return (
                <Grid
                    item
                    xs={1}
                />
            );
        }

        return (
            <Grid
                item
                xs={1}
            >
                {
                    <Checkbox
                        checked={quickFilters[filter]}
                        onChange={onCheckBoxChange}
                    />
                }
            </Grid>
        );
    };

    return (
        <Grid
            item
            {...sizes}
        >
            <Grid container>
                {renderCheckBox()}
                <Grid
                    item
                    xs={quickFilterMode ? 12 : 11}
                >
                    {children}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default DocumentQuickFilterWrapper;
